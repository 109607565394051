import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BaseBlocksRenderer from "../components/blocks/base-blocks-renderer"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BlockHeader from "../components/blocks/block-header"

const TarifasVoltioPage = () => {
  const { strapiVoltioRate } = useStaticQuery(graphql`
    query {
      strapiVoltioRate {
        defaultSeo {
          metaTitle
          metaDescription
          shareImage {
            id
            url
            localFile {
              url
            }
          }
        }
        header {
          title
          cols
          isLarge
          cover {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        blocks {
          __typename
          ...BlockPriceGrid
          ...BaseSectionTitle
          ... on STRAPI__COMPONENT_SHARED_NESTED_ICON_CARD {
            id
            columns
            cards {
              fixedHeight
              hasBg
              iconSubtitle
              id
              cover {
                url
                localFile {
                  url
                }
                alternativeText
              }
            }
          }
          ... on STRAPI__COMPONENT_SHARED_CHARGE_LIST {
            id
            titleCharge
            titleRate
            charges {
              id
              rate
              charge
            }
          }
          ... on STRAPI__COMPONENT_SHARED_BUTTON {
            id
            text
            to
          }
        }
      }
    }
  `)

  const { header } = strapiVoltioRate
  return (
    <Layout>
      <Seo seo={{ ...strapiVoltioRate.defaultSeo, url: "tarifas-voltio" }} />
      <BlockHeader data={header} />
      <BaseBlocksRenderer blocks={strapiVoltioRate.blocks} />
    </Layout>
  )
}

export default TarifasVoltioPage
